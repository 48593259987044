import React from "react";
import { hydrateRoot } from "react-dom/client";
import App from "./app";
import { BrowserRouter } from "react-router-dom";

// Styling
import "./../scss/style.scss";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "./app-reducer";
import { timelineReducer } from "./general-components/content-base/paragraphs/timeline/reducer-timeline";
import { kalenderMonthReducer } from "./general-components/content-base/paragraphs/kalender-monat/reducer-kalender-month";
import { cloudViewReducer } from "./general-components/content-base/paragraphs/content-cloud/cloud-view-reducer";

// GraphQL
import { restHost, graphQlEndpoint } from "./config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "./../../public/data/possibleTypes.json";

const store = createStore(
    combineReducers({
      appStore: appReducer,
      timeLine: timelineReducer,
      kalenderMonth: kalenderMonthReducer,
      contentCloud: cloudViewReducer,
    }),
    window.__PRELOADED_STATE__
  ),
  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  client = new ApolloClient({
    link: from([
      errorLink,
      createHttpLink({
        uri: `${restHost}${graphQlEndpoint}`,
        credentials: "same-origin",
      }),
    ]),
    cache: new InMemoryCache({ possibleTypes }).restore(
      window.__APOLLO_STATE__
    ),
  });

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

const container = document.getElementById("app");
hydrateRoot(
  container,
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter forceRefresh={false}>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
